import { Component } from '@angular/core';
import { ProblemWordsService } from '../problem-words.service';


@Component({
  selector: 'app-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.css']
})
export class ResetButtonComponent {
  constructor(private problemWordsService: ProblemWordsService) {
  }
  clearWords() {
    this.problemWordsService.reset()
    location.reload()
  }
}
