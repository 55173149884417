import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isInteger, isNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  constructor(private http: HttpClient) {
    this.reportOnce("load")
    this.namespace = window.location.hostname.split(".", 1)[0]
    console.debug(`telemetry namespace: ${this.namespace}`);
  }

  reportOnce(id: string) {
    const localStorageKey = `t.first.${id}`
    if (!localStorage.getItem(localStorageKey)) {
      localStorage.setItem(localStorageKey, "true") // This is done synchronously in case this function is called multiple times in quick succession.
      this.hitCounter(`first.${id}`)
    }
  }

  reportFinish() {
    // 1. If this is a new user, increment https://api.countapi.xyz/info/problemwords/first.finish
    this.reportOnce("finish")

    // 2. If this is the third finish in the last 6 hours, increment https://api.countapi.xyz/info/problemwords/session
    const timeNowInSeconds = + new Date() / 1000
    const sixHoursInSeconds = 6 * 60 * 60
    let sessionData = JSON.parse(localStorage.getItem("session") ?? "{}")
    if (!isNumber(sessionData.startTime) || sessionData.startTime > timeNowInSeconds || sessionData.startTime < (timeNowInSeconds - sixHoursInSeconds) || !isInteger(sessionData.finishes) || sessionData.finishes < 0) {
      // reset session if it is more than 6 hours old, or invalid
      sessionData = { startTime: timeNowInSeconds, finishes: 0 }
    }
    if (++sessionData.finishes == 3) {
      this.hitCounter("session")
    }
    localStorage.setItem("session", JSON.stringify(sessionData))
  }

  private hitCounter(counterName: string): void {
    // It is probably unnecessary to unsubscribe from this because it is a finite observable.  https://www.intertech.com/angular-best-practice-unsubscribing-rxjs-observables/
    this.http.get(`https://api.countapi.xyz/hit/${this.namespace}/${counterName}`).subscribe(
      response => console.debug(`telemetry successfully hit /${this.namespace}/${counterName} and got response:`, response),
      error => console.warn(`telemetry failed to hit /${this.namespace}/${counterName} and got error:`, error), // If this error was not handled, it'd get caught by Sentry.
    )
  }

  namespace: String // Based on the domain name. When run locally, it is "localhost", when run on problemwords.com, it is "problemwords".
}
