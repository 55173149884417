import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { assert, expect } from './assert';
import { isUnique } from './utility';
import { Word } from './word';
import { WordPoolService } from './word-pool-service.service';

// TODO: make PersistentMap<T, U> class which may have a cache (it stores a counter with the string and only decodes the string if its counter is invalidated)

// TODO: this service should be aware of what words are and aren't in the dictionary
@Injectable({
  providedIn: 'root'
})
export class ProblemWordsService {

  constructor(private wordPool: WordPoolService) {
    console.time("first-problem-words-load")
    const problemWords = this.loadProblemWordsAsMap()
    console.timeEnd("first-problem-words-load")
    console.debug(`problem word store has size ${problemWords.size}`, problemWords, this.problemWordsListSorted())
    // TODO: use IndexDB
  }

  maxAccruedPenalty = 6
  penalty = 4
  reward = 1

  private loadProblemWords(): {
    [key: string]: number,
  } {
    return JSON.parse(localStorage.getItem("problemWords") ?? "{}") ?? {}
  }

  private loadProblemWordsAsMapPrecursor(): Array<[string, number]> {
    return _.map(this.loadProblemWords(), (value, key) => [key, value])
  }

  private loadProblemWordsAsMap(): Map<string, number> {
    return new Map(this.loadProblemWordsAsMapPrecursor())
  }

  private adjustValue(word: string, adjuster: (x: number) => number): number {
    const problemWords = this.loadProblemWords()
    const originalValue = problemWords[word] ?? 0
    const newValue = adjuster(originalValue)
    expect(newValue >= 0, `'${word}' has value of ${newValue} which is less than 0, deleting problem word`);
    if (originalValue != newValue) {
      console.debug(`${originalValue} -> ${newValue} '${word}'`);
    }
    (<any>(problemWords)[word]) = (newValue <= 0 ? undefined : newValue)
    localStorage.setItem("problemWords", JSON.stringify(problemWords))
    return newValue
  }

  recordAttempt(word: string, accurate: Boolean): number {
    return this.adjustValue(word, originalValue => {
      // TODO: make penalty higher for short words, and lower for long words
      if (originalValue > this.maxAccruedPenalty || originalValue < 0) {
        console.warn(`previous value for "${word}" not in range [${0}, ${this.maxAccruedPenalty}], clamping`)
        originalValue = _.clamp(originalValue, 0, this.maxAccruedPenalty)
      }
      const adjustment = accurate ? -this.reward : this.penalty
      return _.clamp(originalValue + adjustment, 0, this.maxAccruedPenalty)
    })
  }

  reset() {
    // For robustness, we clear all the local storage.
    // We might need to be more careful if we ever store anything other than problem words.
    localStorage.clear();
  }

  /** @returns a list of all problem words, with the worst first. */
  private problemWordsListSorted(): [string, number][] {
    return this.loadProblemWordsAsMapPrecursor()
      .sort((a, b) => { return b[1] - a[1] })
  }

  problemWordsCount(): number {
    return Object.keys(this.loadProblemWords()).length
  }

  private sampleWorst(max: number): [string, number][] {
    return this.problemWordsListSorted().slice(0, max)
  }

  makeMix(n: number, maxRatio = 1): Word[] {
    assert(_.isInteger(n) && n >= 0)
    assert(maxRatio >= 0 && maxRatio <= 1)
    const maxProblemWords = _.round(maxRatio * n)
    const problemWords = this.sampleWorst(maxProblemWords)
    const randomWords = this.wordPool.sample(n - problemWords.length, problemWords.map(([w, s]) => w))
    const allWords = randomWords.concat(problemWords.map(([w, s]) => new Word(w, s)))
    const result = _.shuffle(allWords)
    assert(isUnique(result))
    return result
  }

}
