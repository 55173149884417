class AssertionError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'AssertionError';
    }
}

// TODO: make variadic
// TODO: make return type be `never` when predicate is false
export function assert(predicate: any, message: string | undefined = undefined) {
    if (!predicate) {
        throw new AssertionError(message)
    }
}

/// like assert, but indicated an error that will be recovered from
export function expect(predicate: any, message: any): boolean {
    if (!predicate) {
        console.warn(message)
    }
    return predicate
}