<div style="max-width: 900px;" class="test-area" data-nosnippet>
    <div class="informer">
        <!-- TODO: make the number show the change for the current round, e.g. 24->22 (or maybe put it in a tooltip) -->
        <!-- TODO: handle singular and 0 -->
        <!-- TODO: add button which reveals a list of problem words --> You have {{problemWordsCount()}} problem words.
    </div>
    <div class=words>
        <p>
            <span *ngFor="let word of wordObjects">
                <app-word [word]="word"></app-word>
                <span>{{" "}}</span>
            </span>
        </p>
    </div>
    <div>
        <!-- TODO: show spurious spaces as bullets -->
        <input [ngClass]="{'mistake' : currentInputIsMistake}" (keyup)="onKey($event)" autofocus (focus)="setInputHasFocus(true)" (blur)="setInputHasFocus(false)" [attr.placeholder]="inputPlaceholder()" autocomplete="off" autocorrect="off" autocapitalize="off" type="text" spellcheck="false">
    </div>
    <div style="margin: 13px 0px; min-height: 100px;">
        <span class=results *ngIf="finished()"> {{wordsPerMinute()}} WPM<br>{{accuracyPercent()}}% Accurate</span>
        <!-- TODO: add tooltip explaining how speed and accuracy is calculated -->
    </div>
    <!-- TODO: add option to show score (sum of all problem word scores) -->
</div>