import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { weightedEnglishSubset } from './dictionary/dictionary';
import { Word } from './word';

@Injectable({
  providedIn: 'root'
})
export class WordPoolService {

  sample(n: number, exclusions?: string[]): Word[] {
    return weightedEnglishSubset.sample(n, new Set(exclusions)).map(word => new Word(word, 0))
  }

}
