export enum WordState {
  Unfinished,
  CurrentUnmistaken,
  CurrentMistaken,
  Finished,
}

export class Word {
    constructor(readonly word: string, readonly initialScore : number) {
      this.currentScore = initialScore
    }
    currentScore: number
    mistaken = false
    state = WordState.Unfinished
  }
  