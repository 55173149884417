import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { FORCE_UNDEFINED } from 'src/app/utility';
import { Word, WordState } from 'src/app/word';

@Component({
  selector: 'app-word',
  templateUrl: './word.component.html',
  styleUrls: ['./word.component.css']
})
export class WordComponent {

  @Input() word: Word = FORCE_UNDEFINED

  classes() {
    return {
      'mistaken': this.word.mistaken,
      'problem': this.word.initialScore,
      'current': this.word.state === WordState.CurrentUnmistaken || this.word.state === WordState.CurrentMistaken,
      'current-mistaken': this.word.state === WordState.CurrentMistaken,
      'finished': this.word.state === WordState.Finished
    }
  }

  tooltip() {
    const { currentScore, initialScore } = this.word
    if (initialScore === currentScore) {
      return `${initialScore}`
    }
    // TODO: these quarter-width space characters don't work well with all browsers
    return `${initialScore}\u202F\u202F➔\u202F\u202F${currentScore}`
  }

}
