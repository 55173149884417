import * as _ from "lodash"

import { Component } from '@angular/core';
import { Word, WordState } from "../word";
import { ProblemWordsService } from "../problem-words.service";
import { assert } from "../assert";
import { FORCE_UNDEFINED } from "../utility";
import { TelemetryService } from "../telemetry.service";

declare let gtag: Function;

class DummyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DummyError';
  }
}

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.css'],
})
export class ParagraphComponent {

  constructor(private wordAccuracyMonitor: ProblemWordsService, private telemetryService: TelemetryService) {
    this.reset()
  }

  wordObjects: Word[] = FORCE_UNDEFINED
  position: number = FORCE_UNDEFINED
  currentInputIsMistake: boolean = FORCE_UNDEFINED
  startTime?: number = undefined
  finishTime?: number = undefined

  reset() {
    this.wordObjects = this.generateWords()
    this.position = 0
    this.currentInputIsMistake = false
    this.startTime = undefined
    this.finishTime = undefined
  }

  generateWords(): Word[] {
    const maxFraction = 1
    const numberOfWords = 35 // TODO: stub out ConfigService and retrieve these values from there
    const result = this.wordAccuracyMonitor.makeMix(numberOfWords, maxFraction)
    return result
  }

  problemWordsCount(): number {
    return this.wordAccuracyMonitor.problemWordsCount()
  }

  finishedWordObjects() {
    return this.wordObjects.slice(0, this.position)
  }

  currentWordObject(): Word | undefined {
    return this.wordObjects[this.position]
  }

  unfinishedWordObjects() {
    return this.wordObjects.slice(this.position + 1)
  }

  startIfNotAlreadyStarted() {
    if (this.startTime === undefined) {
      console.debug("started timer", Date.now())
      this.startTime = _.now() / 1000
    }
  }

  finishIfNotAlreadyFinished() {
    assert(this.startTime)
    if (this.finishTime === undefined) {
      console.debug("finished timer", Date.now())
      this.finishTime = _.now() / 1000
      this.telemetryService.reportFinish()
      gtag('event', 'finish', { event_category: 'typing_practice' });
      // TODO: add telemetry for the tenth & hundredth finish (or generically, for the tenth or hundredth of every event)
    }
  }

  // TODO: prevent cursor moving from end
  // TODO: handle backspace and ctrl-backspace through completed words
  // TODO: make backspacing an error, even if the event value was a valid prefix both before and after the backspace
  onKey(event: KeyboardEvent) {
    this.startIfNotAlreadyStarted()

    if (this.finished() && event.key == "Enter") {
      // TODO: capture enter elsewhere in UI
      (<any>(event.target)).value = "" // TODO: try to move this into reset
      this.reset()
      return
    }

    const currentWordObject = this.currentWordObject()
    if (currentWordObject === undefined) {
      this.finishIfNotAlreadyFinished()
      this.currentInputIsMistake = false
      return
    }
    const currentWordString = currentWordObject.word
    const currentWordWithSpace = this.position == this.wordObjects.length - 1 ? currentWordString : currentWordString + " "
    const currentInput: string = (<any>(event.target)).value // TODO: avoid any cast

    if (currentInput.endsWith("devForceException")) {
      throw new DummyError(currentInput)
    }

    if (currentInput.startsWith(currentWordWithSpace)) {
      (<any>(event.target)).value = currentInput.substr(currentWordWithSpace.length)
      currentWordObject.state = WordState.Finished
      this.position++
      // TODO: record errors as early as possible
      if (currentWordObject.mistaken === false) {
        currentWordObject.currentScore = this.wordAccuracyMonitor.recordAttempt(currentWordString, true)
      }
      this.onKey(event) // without this recursive call, an input such as "word w" would be trimmed, but the remaining "w" would not get checked until the user's next key press
      return
    }

    this.currentInputIsMistake = !currentWordWithSpace.startsWith(currentInput)
    currentWordObject.state = this.currentInputIsMistake ? WordState.CurrentMistaken : WordState.CurrentUnmistaken
    if (this.currentInputIsMistake && currentWordObject.mistaken === false) {
      currentWordObject.currentScore = this.wordAccuracyMonitor.recordAttempt(currentWordString, false)
      currentWordObject.mistaken = true
    }

  }

  started() {
    return this.startTime !== undefined
  }

  finished() {
    return this.finishTime !== undefined
  }

  timeTaken(): number | undefined {
    if (!this.startTime || !this.finishTime) {
      return undefined
    }
    return this.finishTime - this.startTime
  }

  characterCount() {
    return _.sum(_.map(this.wordObjects, word => word.word.length)) + this.wordObjects.length - 1
  }

  wordsPerMinute(): number | undefined {
    const timeTaken = this.timeTaken()
    if (!timeTaken) {
      return
    }
    return _.round(this.characterCount() / timeTaken * 60 / 5)
  }

  mistakes(): string[] {
    return this.wordObjects.filter(word => word.mistaken).map(word => word.word)
  }

  mistakeCount(): number {
    return this.mistakes().length
  }

  accuracy() {
    return 1 - (this.mistakeCount() / this.position)
  }

  accuracyPercent() {
    return _.round(this.accuracy() * 100)
  }

  // TODO: move the input to its own component
  private inputHasFocus = true
  setInputHasFocus(value: boolean) {
    this.inputHasFocus = value
  }

  inputPlaceholder() {
    if (!this.inputHasFocus || !document.hasFocus()) {
      return "Click here"
    }
    if (this.finished()) {
      return "Press enter"
    }
    if (!this.started()) {
      return `Type "${this.currentWordObject()?.word}"`
    }
    return undefined
  }

}
