import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { WordComponent } from './paragraph/word/word.component';
import * as Sentry from "@sentry/angular";
import { ResetButtonComponent } from './reset-button/reset-button.component';

// TODO: maybe clear local storage on uncaught exception

@NgModule({
  declarations: [
    AppComponent,
    ParagraphComponent,
    WordComponent,
    ResetButtonComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
