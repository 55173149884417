import { Component, ViewEncapsulation } from '@angular/core';
import * as Sentry from "@sentry/browser";

@Component({
  selector: 'app-root',
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'typing-website';

  ngOnInit() {
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    if (darkMode) {
      // Dark mode is controlled exclusively via the `dark-mode` class on `body`.
      // TODO: this could be an observable such that it updated live when the system dark mode is changed
      document.body.classList.add("dark-mode")
    }
    Sentry.setTag("dark_mode", darkMode);
  }

}
